<template>
  <div class="dashboard">
    <h1 class="subheading grey--text ma-2">我的发票</h1>

    <v-divider></v-divider>

    <v-row class="ma-4">
      <FpImgDlg ref="fpImgDlg" />

      <!-- <AddControllerDlg v-if="userRole == 'client'" @add-controller="reload_controllers"/>
    
     <delete-controller-dlg  ref="delete_controller_dlg" @delete-controller="reload_controllers"/> -->
      <v-spacer></v-spacer>
      <!-- <span class="body-1">Click</span> <a class="mx-1" href="#">here</a>  <span> to Add an Actual Controller</span> -->
    </v-row>

    <v-container fluid class="my-4 ml-4 pr-8">
      <div v-for="(val, key, index) in fp_group" :key="index">
        <v-row class="">{{ key }}</v-row>
        <v-card class="my-6">
          <v-row class="px-4" v-for="fp in val" :key="fp.id">
            <v-col cols="3">
              <div class="caption grey--text mb-2">发票类型</div>
              <div :class="`${fp.update_status}`">{{ fp.type }}</div>
            </v-col>
            <v-col cols="3">
              <div class="caption grey--text mb-2">发票金额</div>
              <div>人民币 {{ fp.amount }} 元</div>
            </v-col>
            <v-col cols="2">
              <div class="caption grey--text mb-2">日期</div>
              <div>{{ fp.date }}</div>
            </v-col>
            <v-col cols="2">
              <div class="caption grey--text mb-2">详情</div>
              <router-link :to="`/fp_detail/${fp.id}`" tag="div">
                <span class="text-decoration-underline blue--text pointer"
                  >查看</span
                >
              </router-link>
            </v-col>
            <v-divider></v-divider>
          </v-row>
          
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import FpImgDlg from "@/components/FpImgDlg.vue";

export default {
  components: { FpImgDlg },

  mounted: function () {
    console.log("hello");
  },
  created: function () {
    // Make a request for a user with a given ID
    this.reload_fp_list();
  },

  data() {
    return {
      fp_group: {},
    };
  },
  methods: {
    reload_fp_list() {
      console.log("reload_fp_list");
      let that = this;
      this.$http
        .get("/fapiao/get_new_fp_list/")
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.fp_group = response.data.fp_group;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    show_image(url) {
      console.log("show_image:" + url);
      this.$refs.fpImgDlg.url = url;
      this.$refs.fpImgDlg.fp_img = true;
      this.$refs.fpImgDlg.file_type = "image";
    },

    show_pdf(url) {
      console.log("show_pdf:" + url);
      this.$refs.fpImgDlg.url = url;
      this.$refs.fpImgDlg.fp_img = true;
      this.$refs.fpImgDlg.file_type = "pdf";
    },
  },
  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
  },
};
</script>
