// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });


import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    role: localStorage.getItem('role') || '',
    accountInfo: localStorage.getItem('accountInfo') || ''
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token ){
      state.status = 'success'
      state.token = token
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.role = ''
      state.accountInfo = ''
    },
    set_role(state, role) {
      state.role = role
    },
    set_accountInfo(state, accountInfo) {
      state.accountInfo = accountInfo
    }
  },

  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        console.log('login')
        console.log(user)
        let baseURL = process.env.VUE_APP_BASE_URL
        console.log(baseURL)
        axios({url: baseURL + '/system/api_login', data: user, method: 'POST' })
        .then(resp => {
          console.log('login_api...')
          console.log(resp.data)
          const token = resp.data.token
          const role = resp.data.role
          const accountInfo = JSON.stringify(resp.data.accountInfo)
          localStorage.setItem('token', token)
          localStorage.setItem('role',role)
          localStorage.setItem('accountInfo', accountInfo)
          axios.defaults.headers.common['Authorization'] = token
          Vue.prototype.$http = axios;
          Vue.prototype.$http.defaults.baseURL = baseURL;
          Vue.prototype.$EventBus.$emit('login')
          
          commit('auth_success', token)
          commit('set_role', role)
          commit('set_accountInfo', accountInfo)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('role')
          reject(err)
        })
      })
  },
  // register({commit}, user){
  //   return new Promise((resolve, reject) => {
  //     commit('auth_request')
  //     axios({url: 'http://localhost:3000/register', data: user, method: 'POST' })
  //     .then(resp => {
  //       const token = resp.data.token
  //       const user = resp.data.user
  //       localStorage.setItem('token', token)
  //       axios.defaults.headers.common['Authorization'] = token
  //       commit('auth_success', token, user)
  //       resolve(resp)
  //     })
  //     .catch(err => {
  //       commit('auth_error', err)
  //       localStorage.removeItem('token')
  //       reject(err)
  //     })
  //   })
  // },

  logout({commit}){
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      commit('logout')
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('accountInfo')
      delete axios.defaults.headers.common['Authorization']
      Vue.prototype.$EventBus.$emit('logout')
      resolve()
    })
  },





  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userRole: state => state.role,
    accountInfo: state => state.accountInfo,
  }
})