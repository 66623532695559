import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index.js'

import Login from '../components/Login.vue'
import NewFpList from "../views/NewFpList.vue"
import FpDetail from "../views/FpDetail.vue"
import Clients from "../views/Clients.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "NewFpList",
    component: NewFpList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/fp_detail/:id",
    name: 'FpDetail',
    component: FpDetail,
    meta: {
      requiresAuth: true
    }

  },

  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: "/projects",
    name: "Projects",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects.vue"),
    meta: {
        requiresAuth: true
      }
  },
  
  {
    path: "/depts",
    name: "Departments",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Departments.vue"),
    meta: {
        requiresAuth: true
      }
  },

  {
    path: "/staff",
    name: "Staff",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Staff.vue"),
    meta: {
        requiresAuth: true
      }
  },

  {
    path: "/team",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/Team.vue"),
    meta: {
        requiresAuth: true
      }
  },

  {
    path: "*",
    name: "notFound",
    component: Login
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;
