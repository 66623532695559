<template>
  <v-container class="my-auto grey lighten-1" fill-height fluid>
  <v-card  width="400" class="mx-auto my-auto">
    <v-card-title >
      <v-img src="rsm-login.png" max-width="80" />
    <h3 class="mx-4">Sign In</h3>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          prepend-icon="mdi-account-circle"
          name="email"
        v-model="email"
        />
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          name="password"
          v-model="password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="login" color="success" class="mr-4">登录</v-btn>
    </v-card-actions>
  </v-card>
  </v-container>
</template>
<script>
  export default {
    data(){
      return {
        email : "",
        password : "",
        showPassword: false,
      }
    },
    methods: {
      login: function () {
        let email = this.email
        let password = this.password
        this.$store.dispatch('login', { email, password })
       .then(() => this.$router.push('/'))
       .catch(err => console.log(err))
      }
    }
  }
</script>
