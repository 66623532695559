<template>
  <div class="fp-detail">
    <h1 class="headline grey--text ma-4">发票详情</h1>
    <div class="my-2 ml-5 caption font-italic font-weight-light">
      <v-row>
        <div>
          <div class="ml-5">
            * <span class="updated">粉红高亮</span
            ><span> 表示已经修改过的项目</span>
          </div>
          <div class="ml-5">
            * <span class="updating">黄色高亮</span
            ><span> 表示当前修改的项目</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="mx-3" small @click="goBack">返回</v-btn>
        <v-btn class="mr-5 success" small :disabled="!updated" @click="submit_change">提交</v-btn>
      </v-row>
    </div>
    <v-container class="my-5">
      <v-row>
        <v-col
          xs="12"
          sm="6"
          md="4"
          lg="3"
          v-for="attr in attrs"
          :key="attr.key"
        >
          <v-card class="text-center ma-0" flat>
            <v-card-text>
              <div>{{ attr.key }}</div>
              <div>
                <v-text-field
                  :class="`center-input ${
                    attr.updated ? 'updated-input' : ''
                  } ${attr.focusing ? 'focus-input' : ''} ${
                    attr.change ? 'change-input' : ''
                  }`"
                  v-model="attr.val"
                  solo
                  flat
                  @focus="onfocus(attr.key)"
                  @change="onchange(attr.key)"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
    <v-container class="my-5">
      <h1 class="title grey--text">发票文件</h1>
      <div class="ma-4">
        <v-card>
          <v-img
            v-if="file_type === 'image'"
            :src="file_path"
            contain
            class="mx-4"
          ></v-img>

          <pdf v-if="file_type === 'pdf'" :src="file_path" class="mx-4" />
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  mounted: function () {
    console.log("hello");
  },
  created: function () {
    // Make a request for a usr with a given ID
    this.reload_fp_detail();
  },

  data() {
    return {
      updated: false,
      attrs: [],
      logs: [],
      file_path: "",
      file_type: "",
      updated_data: {}
    };
  },
  methods: {
    reload_fp_detail() {
      console.log("reload_fp_detail");
      let that = this;
      this.$http
        .get("/fapiao/get_fp_detail/" + this.$route.params.id)
        .then(function (response) {
          // handle success
          console.log(response);
          if (response.data.status == "success") {
            that.attrs = response.data.attrs;
            that.logs = response.data.logs;
            that.file_path = response.data.file_path;
            that.file_type = response.data.file_type;
          } else {
            that.$store.dispatch("logout");
            that.$router.push("login");
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          that.$store.dispatch("logout");
        });
    },

    onfocus(key) {
      console.log("focuse:" + key);
      for (var i = 0; i < this.attrs.length; i++) {
        if (this.attrs[i].key == key) {
          let attr = this.attrs[i];
          attr.focusing = true;
          this.$set(this.attrs, i, attr);
        } else {
          let attr = this.attrs[i];
          attr.focusing = false;
          this.$set(this.attrs, i, attr);
        }
      }
    },

    onchange(key) {
      console.log("change:" + key);
      for (var i = 0; i < this.attrs.length; i++) {
        if (this.attrs[i].key == key) {
          let attr = this.attrs[i];
          attr.change = true;
          this.$set(this.attrs, i, attr);
          this.updated = true;
          this.$set(this.updated_data, [attr.name], attr.val)
        }
      }
    },

    submit_change() {

      let that = this

      this.$http
        .post("/fapiao/update_fp/" + this.$route.params.id, this.updated_data, {

        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status == "success") {
            console.log("SUCCESS!");
            that.dialog = false;
          }
        })
        .catch(function () {
          console.log("FAILURE!!");
        })
        .then(function () {
          that.reload_fp_detail()
          that.updated_data = {}
          that.updated = false
        });
    },
    goBack() {
      this.$router.go(-1);
    },

    // show_image(url) {
    //   console.log('show_image:' + url)
    //   this.$refs.fpImgDlg.url = url
    //   this.$refs.fpImgDlg.fp_img = true
    //   this.$refs.fpImgDlg.file_type = 'image'
    // },

    // show_pdf(url) {
    //   console.log('show_pdf:' + url)
    //   this.$refs.fpImgDlg.url = url
    //   this.$refs.fpImgDlg.fp_img = true
    //   this.$refs.fpImgDlg.file_type = 'pdf'
    // },
  },
  computed: {
    userRole() {
      return this.$store.getters.userRole;
    },
  },
};
</script>

<style scoped>
.center-input >>> input {
  text-align: center;
}
.updated-input >>> input {
  background-color: pink;
}
.focus-input >>> input {
  background-color: yellow;
}

.change-input >>> input {
  background-color: yellow;
}

.updating {
  background-color: yellow;
}
</style>
