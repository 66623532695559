<template>
  <v-app class="grey lighten--4">
    <Navbar />
    <v-main class="mx-4 mb-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
export default {
  name: "App",
  components: { Navbar },

  data: () => ({
    //
  }),

  computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
      created: function () {
        this.$http.interceptors.response.use(undefined, function (err) {
          // eslint-disable-next-line no-unused-vars
          return new Promise(function (resolve, reject) {
            if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
              this.$store.dispatch('logout')
            }
            throw err;
          });
        });
      }
    },
};
</script>
