<template>
    <nav>

        <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
            <span>Awesome! You added a new project!</span>
            <v-btn text color="white" @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-app-bar app>
             <v-app-bar-nav-icon v-if="isLoggedIn" class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-img src="@/assets/logo@2x.png" height="35px" max-width="80px"/>

            <v-toolbar-title>
                <span class="px-4 grey--text">发票通</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <span>{{activeClient}}</span>
            <v-btn v-if="isLoggedIn" text color="grey" @click="logout">
            <span>Sign Out</span>
            <v-icon right>mdi-exit-to-app</v-icon>
            </v-btn>

        </v-app-bar>

        <v-navigation-drawer app v-model="drawer" color="blue lighten-1">
              <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6 white--text">
              {{accountInfo.company}}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text">
              {{accountInfo.name}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
  
        <v-divider></v-divider>
  
        <v-list
          nav
          dark
        >
         <v-subheader>发票</v-subheader>
          <v-list-item
            v-for="item in fp_links"
            :key="item.text"
            link :to="item.route"
          >
            <v-list-item-icon>
              <v-icon class="white--text">mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-subheader>报销</v-subheader>
          <v-list-item
            v-for="item in rb_links"
            :key="item.text"
            link :to="item.route"
          >
            <v-list-item-icon>
              <v-icon class="white--text">mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-subheader>公司</v-subheader>
          <v-list-item
            v-for="item in mp_links"
            :key="item.text"
            link :to="item.route"
          >
            <v-list-item-icon>
              <v-icon class="white--text">mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-navigation-drawer>
       
    </nav>
</template>
<script>
export default {
     computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      accountInfo: function() {
        console.log(' get accountInfo');
        console.log(this.$store.getters.accountInfo);
        return JSON.parse(this.$store.getters.accountInfo);
      }
    },

    mounted(){
            this.$EventBus.$on('login',()=>{
                console.log('login, open drawer')
                this.drawer = true;
            })
            this.$EventBus.$on('logout',()=>{
                console.log('logout, close drawer')
                this.drawer = false;
            })
        },

    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      },
    },
    
    data() {
        return {
            fp_links: [
                { icon: 'folder', text: '新发票', route: '/'},
                { icon: 'folder', text: '已使用的发票', route: '/projects' },
                { icon: 'account-multiple', text: 'Team', route: 'team'}
            ],
             rb_links: [
                { icon: 'folder', text: '新报销', route: '/'},
                { icon: 'folder', text: '已完成的报销', route: '/projects' },
                { icon: 'account-multiple', text: 'Team', route: 'team'}
            ],
            mp_links: [

                { icon: 'office-building', text: '部门管理', route: '/depts'},
                { icon: 'account-multiple', text: '员工管理', route: '/staff'}
            ],
            snackbar: false,
            drawer: false
        }
    }
    
}
</script>

<style scoped>

</style>